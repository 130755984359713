import { useFetch } from "react-async";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { useTranslation } from "react-i18next";
import LangSelect from "./components/LangSelect";
import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import XIcon from "@mui/icons-material/X"; // replace with the actual icon
import WhatsAppIcon from "@mui/icons-material/WhatsApp"; // replace with the actual icon
import FacebookIcon from "@mui/icons-material/Facebook"; // import Facebook icon
import ReactGA from 'react-ga';

ReactGA.initialize('G-D9KYV6NWWL');
const userLang = navigator.language || navigator.userLanguage; 

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: require("./locales/en/translation.json"),
    },
    bengali: {
      translation: require("./locales/bengali/translation.json"),
    },
    hindi: {
      translation: require("./locales/hindi/translation.json"),
    },
    assamese: {
      translation: require("./locales/assamese/translation.json"),
    },
  },
  lng: userLang,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});
// To be replaced by the endpoint of the API deployed through the CloudFormation Template
const APIEndPoint = "https://api.instaed.live";

const StyledTextField = styled(TextField)({
  "@media (min-width:600px)": {
    width: "30%", // for desktop and larger devices
  },
  "@media (max-width:600px)": {
    width: "80%", // for mobile devices
  },
});

const StyledLangSelect = styled(LangSelect)({
  display: "flex",
  justifyContent: "center",
  "& ul": {
    fontSize: "0.7rem",
  },
  "@media (min-width:600px)": {
    width: "30%", // for desktop and larger devices
  },
  "@media (max-width:600px)": {
    width: "80%", // for mobile devices
  },
});

function App() {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState(""); // new state variable
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false); // new state variable for controlling the dialog

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isLoading) {
      return;
    }
    // Simple email validation
    if (!email) {
      setMessage(t("emptyEmail"));
      setMessageType("error");
      setTimeout(() => setMessage(""), 3000); // hide the message after 3 seconds
      return;
    }
    setIsLoading(true);

    const response = await fetch(
      APIEndPoint + `/cb/user/waitlist?email=${email}`,
      {
        method: "POST",
      }
    );

    // write code for if resposne return 1, then show the success message
    // else show your response has been recorded
    if (response.ok) {
      let res = await response.json();
      if (res == 1) {
        ReactGA.event({
          category: 'User',
          action: 'Joined Waitlist'
        });
        setOpen(true);
        setEmail(""); // clear the email input
        // setMessage(t("successEmail"));
        // setMessageType("success");
        // setTimeout(() => setMessage(""), 5000); // hide the message after 3 seconds
      } else {
        setMessage(t("responseRecorded"));
        setMessageType("success");
        setTimeout(() => setMessage(""), 5000); // hide the message after 3 seconds
      }
    } else {
      setMessage(t("failedEmail"));
      setMessageType("error"); // set message type to error
    }
    setTimeout(() => {
      setMessage("");
      setMessageType(""); // clear message type
    }, 5000); // hide the message after 3 seconds
    setIsLoading(false);
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <Typography
        variant="h4"
        gutterBottom
        style={{ textAlign: "center", paddingTop: "20px" }}
      >
        {t("hello")}
      </Typography>

      <Typography
        variant="h6"
        gutterBottom
        style={{ textAlign: "center", paddingTop: "20px" }}
      >
        {t("subHeading")}
      </Typography>
      
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "16px",
          flex: 1,
        }}
      >
        <StyledTextField
          id="outlined-basic"
          variant="outlined"
          label={t("email")}
          placeholder={t("enterEmail")}
          value={email}
          onChange={handleChange}
        ></StyledTextField>
        <Button variant="outlined" type="submit">
          {t("submit")}
        </Button>
        <StyledLangSelect />
      </form>
      {message && (
        <div
          style={{
            position: "fixed",
            bottom: "20px",
            left: "50%",
            transform: "translateX(-50%)",
            padding: "10px 20px",
            backgroundColor: messageType === "error" ? "#e74c3c" : "#4CAF50", // red for error, green for success
            color: "white",
            zIndex: 1000, // high z-index to overlay the page
            borderRadius: "5px",
          }}
        >
          <p>{message}</p>
        </div>
      )}
      <Dialog open={open} onClose={handleClose}>
        <h1>Thank you for joining the waitlist!</h1>
        <p>Share on:</p>
        <List>
          <ListItem>
            <IconButton
              onClick={() =>
                window.open(
                  "https://www.X.com/share?url=instaed.live",
                  "_blank"
                )
              }
            >
              <XIcon />
            </IconButton>
            <IconButton
              onClick={() =>
                window.open(
                  "https://www.facebook.com/sharer/sharer.php?u=instaed.live",
                  "_blank"
                )
              }
            >
              <FacebookIcon />
            </IconButton>
            <IconButton
              onClick={() =>
                window.open("https://wa.me/?text=instaed.live", "_blank")
              }
            >
              <WhatsAppIcon />
            </IconButton>
          </ListItem>
        </List>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const APIResult = () => {
  const { data, error } = useFetch(APIEndPoint, {
    headers: { accept: "application/json" },
  });
  if (error) return <p>{error.message}</p>;
  if (data) return <p>{data.message}</p>;
  return null;
};

const styles = {
  form: {
    display: "flex",
    "flex-direction": "column",
    "align-items": "flex-start",
  },
};

export default App;
